<template>
    <div class="login-page">
        <div class="login-container">
            <img class="login-logo" src="@/components/assets/logoPC.svg" alt="">
            <Error v-if="errorText" :text="errorText"/>
            <h1 class="login-title">{{ $t('global.welcome') }}</h1>
            <p class="login-subtitle">{{ $t('global.welcomeSubText') }}</p>
            <div class="login-input-container">
                <label class="login-input-text">{{ $t("global.username") }}</label>
                <input v-model="username" class="login-input" type="text" required>
            </div>
            <div class="login-input-container password-container">
                <label class="login-input-text">{{ $t("global.password") }}</label>
                <input v-model="password" class="login-input pass" :type="isPasswordShow ? 'text' : 'password'" required>
                <img v-if="!isPasswordShow" @click="toggleShowPassword" class="eye-img" src="@/components/assets/eye.svg" alt="">
                <img v-else class="eye-img" @click="toggleShowPassword" src="@/components/assets/close-eye.svg" alt="">
                <p @click="goToForgetPassPage" class="login-input-forgot">{{ $t("generalTabs.forgotPassword") }}?</p>
            </div>

            <button :class="!username || !password ? 'form-inactive-btn' : ''" @click="login(username, password)" class="login-btn">{{ $t("global.login") }}</button>
            <div class="login-privacy-policy-container">
                <span class="login-privacy-policy-text">{{ $t('generalTabs.loginPrivacyPolicyText') }}</span>
                <a class="login-privacy-policy" href="/PrivacyPolicy" target="_blank">{{ $t('generalTabs.privacyPolicy') }}</a>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        login: Function,
        goToForgetPassPage: Function,
        createdMethod: Function,
        errorText: String
    },
    data() {
        return {
            username: null, 
            password: null,
            isPasswordShow: false
        }
    },
    components: {
        Error: () => import('./components/Error.vue')
    },
    methods: {
        toggleShowPassword() {
            this.isPasswordShow = !this.isPasswordShow
        }
    },
    created() {
        this.createdMethod()
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.login-container {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: #1F212799;
    backdrop-filter: blur(10px);
    padding: 60px 32px;
    border-radius: 12px;
}

.login-logo {
    width: 104px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.login-container h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 35, 28px;
    margin-bottom: 16px;
}

.login-container .login-subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 19, 5px;
    margin-bottom: 24px;
}

.login-input-container label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16, 38px;
    margin-bottom: 8px;
}

.login-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    outline: none;
    background-color: #FFFFFF0A;
    backdrop-filter: blur(10px);


    font-weight: 400;
    font-size: 15px;
    line-height: 16.38px;
    margin-bottom: 16px;
}
.password-container {
    position: relative;
}
.eye-img {
    position: absolute;
    top: 42%;
    right: 5%;
    cursor: pointer;
}
.login-input.pass {
    margin-bottom: 8px;
}
.login-input:focus {
    border: 1px solid #4E4BCD;
}

.login-input-forgot {
    text-align: end;
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-size: 13px;
}

.login-btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.64px;
    border-radius: 8px;
    padding: 12px 0;
    margin-top: 8px;
    transition: 0.2s all;
}

.form-inactive-btn {
    background-color: #3C3A46;
    color: #1D1B29;
    pointer-events: none;
}
.login-privacy-policy-container {
    font-family: 'Wix Madefor Display', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    color: #FFFFFF99;
    margin-top: 24px;
}
.login-privacy-policy {
    color: #918FFA !important;
}

@media only screen and (max-width: 425px) {
	.login-container {
        max-width: auto;
        margin: 10px;
    }
    .login-container h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 375px) {
	
}
</style>